import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import LogoSecondary from '../../resources/assets/images/LogoSecondary';
import Slide from '../../components/Slide';
import Button from '../../components/form/Button';

export default function HomeView(): JSX.Element {
  const { config } = useSelector((store: RootState) => store.config);
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Home' });
  const navigate = useNavigate();

  return (
    <Slide
      className="h-screen grid grid-cols-11"
    >
      <div className="max-h-screen col-span-6 py-8 flex flex-col justify-between shadow">
        <h2 className="text-center px-6">{config.header}</h2>
        <div className="flex justify-center bg-transparent h-5/6 w-full p-10">
          <img src={config.image_url} alt="" className="aspect-square object-cover rounded-2xl shadow" />
        </div>
        <div className="flex mx-auto">
          <span className="h-fix m-auto text-[#BDBDBD]">{t('labels.powered_by')}</span>
          <LogoSecondary className="ml-2" />
        </div>
      </div>
      <div className="max-h-screen col-span-5 bg-brand-secondary px-8 pt-10 pb-16 flex flex-col justify-between">
        <img className="w-1/3 mx-auto" src={config.logo_url} alt="" />
        <h3 className="text-center px-6">{config.subheader}</h3>
        <Button
          variant="outline"
          size="xl"
          className="bg-brand-primary text-white py-5 h-fit mb-16"
          onClick={() => navigate('/phone-number')}
          hasHaptic
        >
          <h3>{config.home_screen_button_text}</h3>
        </Button>
      </div>
    </Slide>
  );
}
