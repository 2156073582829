import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import ConfigReducer from './slices/ConfigSlice';
import EventHelperReducer from './slices/EventHelperSlice';
import RewardAccountReducer from './slices/RewardAccountSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const reducers = combineReducers({
  eventHelper: EventHelperReducer,
  config: ConfigReducer,
  rewardAccount: RewardAccountReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

// eslint-disable-next-line
const rootReducer = (state: any, actions: any) => {
  if (actions.type === 'RESET_STORE') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return persistedReducer(state, actions);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
