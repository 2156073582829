export default {
  header: 'Nummer eingeben · digital Stempel erhalten',
  labels: {
    powered_by: 'powered by',
  },
  timer: 'Zurück',
  privacy_policy: 'Mit der Eingabe meiner Telefonnummer erkläre ich mich mit den Allgemeinen Geschäftsbedingungen und der Datenschutzerklärung einverstanden, und FoodAmigos-Benachrichtigungen per Auto-SMS zu erhalten.',
  error_codes: {
    422: 'Ungültige Telefonnummer. Bitte versuche es erneut',
    default: 'Da ist was schief gelaufen. Bitte versuche es erneut oder überprüfe die Internetverbindung.',
  },
};
