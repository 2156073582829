import React from 'react';
import { useTranslation } from 'react-i18next';
import Banner from './Banner';
import WifiSlashIcon from '../../resources/assets/images/icons/WifiSlashIcon';

export default function ConnectionLostBanner(): JSX.Element {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Banners:ConnectionLostBanner' });

  return (
    <Banner
      type="warning"
      text={t('text')}
      icon={<WifiSlashIcon />}
    />
  );
}
