import * as React from 'react';
import { SvgProps } from '../types';

export default function BellIcon(props: SvgProps): JSX.Element {
  const {
    width,
    height,
    color,
    style,
    className,
  } = props;

  const _width = width ?? 72;
  const _height = height ?? _width;
  const _color = color ?? '#000000';

  return (
    <svg
      width={_width}
      height={_height}
      viewBox={`0 0 ${_width} ${_height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path d="M19.5074 20.9256C20.3547 12.4525 27.4847 6 36 6C44.5153 6 51.6452 12.4525 52.4926 20.9256L53.3524 29.524C53.4047 30.0469 53.4308 30.3083 53.4622 30.5656C53.8948 34.1152 55.115 37.5232 57.0338 40.5406C57.1729 40.7594 57.3187 40.978 57.6102 41.4152L60.1936 45.2904C62.5525 48.8288 63.732 50.598 63.2144 52.0237C63.1165 52.2933 62.9805 52.5475 62.8105 52.7784C61.9113 54 59.785 54 55.5323 54H16.4677C12.215 54 10.0887 54 9.18953 52.7784C9.01952 52.5475 8.8835 52.2933 8.78563 52.0237C8.26802 50.598 9.44749 48.8288 11.8064 45.2904L14.3898 41.4152C14.6813 40.978 14.8271 40.7594 14.9662 40.5406C16.885 37.5232 18.1052 34.1152 18.5378 30.5656C18.5692 30.3083 18.5953 30.0469 18.6476 29.524L19.5074 20.9256Z" fill={_color} />
      <path d="M30.2044 61.8882C30.5463 62.2072 31.2996 62.489 32.3474 62.69C33.3953 62.891 34.6792 63 36 63C37.3208 63 38.6047 62.891 39.6526 62.69C40.7004 62.489 41.4537 62.2072 41.7956 61.8882" stroke={_color} strokeWidth="6" strokeLinecap="round" />
    </svg>
  );
}
