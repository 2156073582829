import React from 'react';

export default [
  {
    path: 'phone-number',
    name: 'PhoneNumber',
    component: React.lazy(() => import('../views/phone-number')),
  },
  {
    path: 'stamps',
    name: 'Stamps',
    component: React.lazy(() => import('../views/stamps')),
  },
  {
    path: 'stamp-card',
    name: 'StampCard',
    component: React.lazy(() => import('../views/stamp-card')),
  },
  // {
  //   path: 'passcode',
  //   name: 'Passcode',
  //   component: React.lazy(() => import('../views/passcode')),
  // },
  {
    path: 'stamp-card-input',
    name: 'StampCardInput',
    component: React.lazy(() => import('../views/stamp-card-input')),
  },
  {
    path: 'balance',
    name: 'Balance',
    component: React.lazy(() => import('../views/balance')),
  },
  {
    path: 'logout',
    name: 'Logout',
    component: React.lazy(() => import('../views/logout')),
  },
];
