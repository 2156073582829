import Auth from './auth';
import Balance from './balance';
import Error from './error';
import Home from './home';
import Passcode from './passcode';
import PhoneNumber from './phone-number';
import StampCard from './stamp-card';
import StampCardInput from './stamp-card-input';
import Stamps from './stamps';

export default {
  Auth,
  Balance,
  Error,
  Home,
  Passcode,
  PhoneNumber,
  StampCard,
  StampCardInput,
  Stamps,
};
