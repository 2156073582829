export default {
  header: 'Enter your number · get digital stamps',
  labels: {
    powered_by: 'powered by',
  },
  timer: 'Returning back',
  privacy_policy: 'By entering my phone number, I agree to the terms and conditions, privacy policy, to receive FoodAmigos notifications via auto text! Unsubsribe anytime and still participate to get rewards.',
  error_codes: {
    422: 'Invalid phone number. Please try again.',
    default: 'Something unexpected happened. Please try again or check your internet connection.',
  },
};
