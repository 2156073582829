// @ts-ignore
import detectBrowserLanguage from 'detect-browser-language';
import { SupportedLanguages } from '../../constants/constants';

export const LANGUAGE_KEY = 'APP_LANGUAGE';

class LocalizationManager {
  getLanguage(): null|string {
    return localStorage.getItem(LANGUAGE_KEY);
  }

  saveLanguage(language: string): void {
    localStorage.setItem(LANGUAGE_KEY, language);
  }

  getLanguageFromUrl(): null|string {
    try {
      // @ts-ignore
      return qs.parse(window.location.href)?.lng ?? null;
    } catch (error) {
      return null;
    }
  }

  getPreferredLanguage(): null|string {
    try {
      const preferredBrowserLanguage = detectBrowserLanguage();
      const language = preferredBrowserLanguage?.split(/-|_/)[0];

      return Object.values(SupportedLanguages).includes(language)
        ? language
        : null;
    } catch (error) {
      return null;
    }
  }
}

export default new LocalizationManager();
