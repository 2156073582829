import React, {
  FormEvent, useContext, useMemo, useState,
} from 'react';
import tw from 'twin.macro';
import {
  FormControl,
  Input,
} from '@vechaiui/react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../App';
import Logo from '../../resources/assets/images/Logo';
import AuthorizationManager from '../../services/auth/AuthorizationManager';
import ReduxHooks from '../../store/ReduxHooks';
import { setConfig } from '../../store/slices/ConfigSlice';
import Banner from '../../components/banners/Banner';
import Button from '../../components/form/Button';

const Form = tw.form`space-y-4 mt-4`;

export default function LoginView(): JSX.Element {
  // @ts-ignore
  const logIn = useContext(AuthContext)?.logIn;

  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Auth' });

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [bannerText, setBannerText] = useState('');

  const handleError = (status: number): void => {
    setShowBanner(true);
    setTimeout(() => setShowBanner(false), 3000);

    switch (status) {
      case 401: return setBannerText(t('error_codes.401'));
      default: return setBannerText(t('error_codes.default'));
    }
  };

  const submit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();

    setLoading(true);
    const { success, data, status } = await AuthorizationManager.login({
      username,
      password,
    });
    setLoading(false);

    if (!success) {
      return handleError(status);
    }

    ReduxHooks.dispatch(
      setConfig(data?.config),
    );

    return logIn({
      token: data?.auth_token,
    });
  };

  const renderBanner = useMemo(() => showBanner
    && (
      <Banner
        type="error"
        text={bannerText}
        isDismissible
        onDismiss={() => setShowBanner(false)}
      />
    ), [showBanner, bannerText]);

  return (
    <>
      <div className="w-1/3 m-auto bg-base">
        <Logo className="m-auto" />
        <h4 className="text-center mt-4">{t('header')}</h4>
        <Form onSubmit={submit}>
          <FormControl id="username">
            <Input
              placeholder={t('form:username.placeholder')}
              autoComplete="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </FormControl>
          <FormControl id="password">
            <Input
              type="password"
              placeholder={t('form:password.placeholder')}
              autoComplete="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <Button
            type="submit"
            variant="solid"
            size="xl"
            className="w-full bg-primary"
            color="primary"
            loading={loading}
            disabled={loading}
            hasHaptic
          >
            {t('form.submit')}
          </Button>
        </Form>
      </div>
      {renderBanner}
    </>
  );
}
