export default {
  header: 'Wie viele Stempel für diese Bestellung?',
  subheader: 'Du erhälst für jedes Getränk und Gebäck einen Stempel',
  labels: {
    stamps: 'Stempel',
  },
  buttons: {
    continue: 'Weiter',
    check_balance: 'Meine Prämie einlösen 🎉',
  },
  error_codes: {
    403: 'Du hast soeben Punkte erhalten. Bitte bestelle etwas Weiteres oder versuchen es in 10 Minuten erneut.',
    422: 'Ungültige Telefonnummer. Bitte versuche es erneut',
    default: 'Da ist was schief gelaufen. Bitte versuche es erneut oder überprüfe die Internetverbindung.',
  },
};
