import { extendTheme, colors } from '@vechaiui/react';
import { ColorScheme } from './types';

export const defaultColorScheme: ColorScheme = {
  id: 'default',
  type: 'light',
  colors: {
    bg: {
      base: '#ffffff',
      fill: colors.trueGray['900'],
    },
    text: {
      foreground: '#1C1D28',
      muted: colors.trueGray['300'],
      secondary: '#E0E0E0',
      link: '#2F80ED',
      gray: '#5F6974',
    },
    primary: {
      default: '#69CE8C',
      50: '#69CE8C',
      100: '#69CE8C',
      200: '#69CE8C',
      300: '#69CE8C',
      400: '#69CE8C',
      500: '#69CE8C',
      600: '#69CE8C',
      700: '#69CE8C',
      800: '#69CE8C',
      900: '#69CE8C',
    },
    secondary: '#E0E0E0',
    brand: {
      primary: '#D5AE82',
      secondary: '#F5EBE0',
    },
    neutral: colors.warmGray,
  },
};

export default extendTheme({
  cursor: 'pointer',
  colorSchemes: {
    defaultColorScheme,
  },
});
