import React from 'react';
import { useTranslation } from 'react-i18next';
import Banner from './Banner';
import WifiIcon from '../../resources/assets/images/icons/WifiIcon';

interface Props {
  onDismiss: () => void;
}

export default function ConnectionEstablishedBanner(props: Props): JSX.Element {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Banners:ConnectionEstablishedBanner' });

  const { onDismiss } = props;

  return (
    <Banner
      type="success"
      text={t('text')}
      icon={<WifiIcon />}
      isDismissible
      onDismiss={onDismiss}
    />
  );
}
