import i18n, {
  InitOptions,
  LanguageDetectorAsyncModule,
  Services,
} from 'i18next';
import { initReactI18next } from 'react-i18next';
// @ts-ignore
import en from '../../resources/lang/en';
// @ts-ignore
import de from '../../resources/lang/de';
import LocalizationManager from './LocalizationManager';

const DEFAULT_LANGUAGE = 'de';

const languageDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  init: (services: Services, detectorOptions: object, i18nextOptions: InitOptions) => {
    //
  },
  detect: (callback: (lng: string) => void): void => {
    const languageFromUrl = LocalizationManager.getLanguageFromUrl();

    if (languageFromUrl) {
      return callback(languageFromUrl);
    }

    const browserLanguage = LocalizationManager.getLanguage();

    if (!browserLanguage) {
      return callback(LocalizationManager.getPreferredLanguage() ?? DEFAULT_LANGUAGE);
    }

    return callback(browserLanguage);
  },
  cacheUserLanguage: (lng: string) => {
    LocalizationManager.saveLanguage(lng);
  },
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      de,
      en,
    },
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
  });
