import * as React from 'react';
import { SvgProps } from '../types';

export default function WifiIcon(props: SvgProps): JSX.Element {
  const {
    width,
    height,
    color,
    style,
    className,
  } = props;

  const _width = width ?? 32;
  const _height = height ?? _width * 0.75;
  const _color = color ?? '#FFFFFF';

  return (
    <svg
      width={_width}
      height={_height}
      viewBox={`0 0 ${_width} ${_height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path d="M2.13383 9.84324C2.42173 10.1586 2.87413 10.1448 3.17574 9.81582C6.56192 6.23771 11.0174 4.34583 15.9939 4.34583C21.0115 4.34583 25.4807 6.23771 28.8532 9.84324C29.141 10.1311 29.5797 10.1174 29.8676 9.81582L31.7869 7.88282C32.0611 7.60863 32.0611 7.25219 31.8418 6.99172C28.5378 2.92007 22.3824 0 15.9939 0C9.61908 0 3.44992 2.90636 0.159701 6.99172C-0.0596471 7.25219 -0.0596471 7.60863 0.200829 7.88282L2.13383 9.84324ZM7.8643 15.5463C8.19332 15.8616 8.63202 15.8342 8.94733 15.4914C10.6199 13.6681 13.2932 12.3794 16.0076 12.3932C18.7357 12.3794 21.409 13.6955 23.1227 15.5326C23.4106 15.8616 23.8219 15.8479 24.1372 15.5326L26.2895 13.4214C26.55 13.1746 26.5774 12.8319 26.3444 12.5577C24.2057 9.96662 20.2986 8.10217 16.0076 8.10217C11.7029 8.10217 7.79575 9.96662 5.65711 12.5577C5.42405 12.8319 5.45147 13.1609 5.71195 13.4214L7.8643 15.5463ZM16.0076 23.1412C16.3366 23.1412 16.6245 22.9904 17.1866 22.4557L20.5454 19.2204C20.7921 18.9873 20.847 18.6034 20.6276 18.3293C19.668 17.1091 17.9269 16.1221 16.0076 16.1221C14.0335 16.1221 12.265 17.1503 11.319 18.4389C11.1545 18.6857 11.2368 18.9873 11.4698 19.2204L14.8286 22.4557C15.377 22.9767 15.6786 23.1412 16.0076 23.1412Z" fill={_color} />
    </svg>
  );
}
