import { batch } from 'react-redux';
import { AnyAction } from 'redux';
import { store, RootState } from './index';

class ReduxHooks {
  dispatch = (action: AnyAction): AnyAction => store.dispatch(action);

  getStore = (): RootState => store.getState();

  clearStore = (): AnyAction => store.dispatch({ type: 'RESET_STORE' });

  batchActions = (actions: AnyAction[]): void => batch(() => {
    actions.forEach((action) => {
      store.dispatch(action);
    });
  });
}

export default new ReduxHooks();
