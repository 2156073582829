import Banners from './bannners';
import Form from './form';
import RewardCard from './RewardCard';
import SkipButton from './SkipButton';

export default {
  Banners,
  Form,
  RewardCard,
  SkipButton,
};
