export default {
  header: 'Loyalty Tablet - Stamp cards revolutionized',
  form: {
    username: {
      placeholder: 'Username',
    },
    password: {
      placeholder: 'Password',
    },
    submit: 'Log in',
  },
  error_codes: {
    401: 'Invalid credentials',
    default: 'Something unexpected happened. Please try again or check your internet connection.',
  },
};
