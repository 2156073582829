import { colors, extendTheme } from '@vechaiui/react';
import { useSelector } from './useSelector';
import { RootState } from '../store';
import { defaultColorScheme } from '../theme';
import { ColorScheme } from '../theme/types';

export const useTheme = (): any => {
  const { config } = useSelector((store: RootState) => store.config);

  const brandColorScheme: ColorScheme = {
    id: 'brand',
    type: 'light',
    colors: {
      bg: {
        base: '#ffffff',
        fill: colors.trueGray['900'],
      },
      text: {
        foreground: '#1C1D28',
        muted: colors.trueGray['300'],
        secondary: '#E0E0E0',
        link: '#2F80ED',
        gray: '#5F6974',
      },
      primary: {
        default: '#69CE8C',
        50: '#69CE8C',
        100: '#69CE8C',
        200: '#69CE8C',
        300: '#69CE8C',
        400: '#69CE8C',
        500: '#69CE8C',
        600: '#69CE8C',
        700: '#69CE8C',
        800: '#69CE8C',
        900: '#69CE8C',
      },
      secondary: '#E0E0E0',
      brand: {
        primary: config.primary_color,
        secondary: config.secondary_color,
      },
      neutral: colors.warmGray,
    },
  };

  return extendTheme({
    cursor: 'pointer',
    colorSchemes: {
      defaultColorScheme,
      brandColorScheme,
    },
  });
};
