export default {
  header: 'Current balance',
  headers: {
    how_it_works: 'How it works 👋',
  },
  texts: {
    how_it_works: 'We just sent you a SMS on how to track & redeem your rewards using the FoodAmigos app',
  },
  labels: {
    points_one: 'point',
    points_other: 'points',
    rewards: 'Rewards',
  },
  links: {
    stamp_card: 'I have an existing loyalty card with stamps ->',
  },
  buttons: {
    done: 'Done',
    redeem_reward: 'Redeem Reward Now 🎉',
  },
  modals: {
    redeem_reward: {
      title: 'You have a reward available',
      reward_one: 'Redeem {{count}} point',
      reward_other: 'Redeem {{count}} points',
      discount: '{{count}}% off',
      buttons: {
        redeem: 'Redeem Reward Now 🎉',
        not_now: 'Not, Now',
      },
    },
  },
  toasts: {
    reward_redeemed: 'Reward redeemed. Tell our staff what you would like as a reward',
    failed_to_redeem_reward: 'Redeeming reward failed. Please try again',
  },
};
