import * as React from 'react';
import { Button as BaseButton, ButtonProps, omit } from '@vechaiui/react';
import classnames from 'classnames';

interface Props extends ButtonProps {
  hasHaptic?: boolean;
}

export default function Button(props: Props): JSX.Element {
  const {
    variant, color, className, hasHaptic, onClick,
  } = props;

  const _onClick = !hasHaptic ? onClick : (event: any): void => {
    // @ts-ignore
    if (window.navigator.vibrate) {
      navigator.vibrate(200);
    }

    if (onClick) {
      onClick(event);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { hasHaptic: _hasHaptic, ..._props } = {
    ...props,
    onClick: _onClick,
  };

  return (
    <BaseButton
      {..._props}
      className={classnames(className, {
        'bg-brand-primary text-white ': variant === 'solid' && color === 'brand-primary',
        'bg-brand-secondary text-white': variant === 'solid' && color === 'brand-secondary',
        shadow: variant !== 'link',
      })}
    />
  );
}
