import { motion, AnimatePresence } from 'framer-motion';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

interface Props extends React.PropsWithChildren<any> {
  className?: string;
}

export default function Slide(props: Props): JSX.Element {
  const { children } = props;

  const location = useLocation();
  // @ts-ignore
  const back = Boolean(location.state?.navigatingBack);

  const variants = {
    enter: {
      x: back ? -1000 : 1000,
      opacity: 0,
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: {
      zIndex: 0,
      x: back ? 1000 : -1000,
      opacity: 0,
    },
  };

  return (
    // @ts-ignore
    <AnimatePresence>
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: 'spring', stiffness: 300, damping: 30 },
          opacity: { duration: 0.2 },
        }}
        {...props}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}
