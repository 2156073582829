import React from 'react';
import { useTranslation } from 'react-i18next';
import BellIcon from '../../resources/assets/images/icons/BellIcon';
import Button from '../../components/form/Button';

export default function ErrorView(): JSX.Element {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Error' });

  // eslint-disable-next-line no-return-assign
  const navigateHome = (): string => window.location.href = '/home';

  return (
    <div className="flex flex-col justify-center h-screen">
      <div className="flex flex-col w-1/2 mx-auto">
        <BellIcon className="mx-auto" />
        <h3 className="mt-4 text-center">{t('header')}</h3>
        <h5 className="mt-4 text-center">{t('text')}</h5>
        <Button
          variant="solid"
          size="xl"
          color="brand-primary"
          className="mt-5 py-4 h-fit mx-auto mb-24 w-1/2"
          onClick={navigateHome}
          hasHaptic
        >
          <h4>{t('button')}</h4>
        </Button>
      </div>
    </div>
  );
}
