export default {
  header: 'How many stamps for this order?',
  subheader: 'You get a stamp for each drink and pastry',
  labels: {
    stamps: 'Stamps',
  },
  buttons: {
    continue: 'Continue',
    check_balance: 'Redeem my reward 🎉',
  },
  error_codes: {
    403: 'You just earned points. Please purchase something else or try again in 10 minutes.',
    422: 'Invalid phone number. Please try again.',
    default: 'Something unexpected happened. Please try again or check your internet connection.',
  },
};
