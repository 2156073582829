import BackendApiClient from './BackendApiClient';
import { Response } from './types';
import { ConfigData } from '../../constants/responses';

class ConfigManager {
  get = (): Promise<Response<ConfigData>> => BackendApiClient.requestAuthorized({
    method: 'GET',
    url: '/loyalty/config',
  });
}

export default new ConfigManager();
