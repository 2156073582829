import { AnyAction } from 'redux';
import ReduxHooks from '../../store/ReduxHooks';
import { setEvent, resetEvent } from '../../store/slices/EventHelperSlice';

class HelperMethods {
  triggerEventSideEffects = (event: AnyAction): AnyAction => ReduxHooks.dispatch(
    setEvent(event),
  );

  resetEventSideEffect = (): AnyAction => ReduxHooks.dispatch(
    resetEvent(),
  );

  formatPhoneNumber = (phoneNumber: string): string => (phoneNumber.startsWith('0') ? phoneNumber.slice(1) : phoneNumber);
}

export default new HelperMethods();
