import * as React from 'react';
import { SvgProps } from '../types';

export default function AlertIcon(props: SvgProps): JSX.Element {
  const {
    width,
    height,
    color,
    style,
    className,
  } = props;

  const _width = width ?? 24;
  const _height = height ?? _width;
  const _color = color ?? '#FFFFFF';

  return (
    <svg
      width={_width}
      height={_height}
      viewBox={`0 0 ${_width} ${_height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path d="M12 1.5L1 21H23L12 1.5ZM10.5 8H13.5V13H10.5V8ZM12 19C10.9 19 10 18.1 10 17C10 15.9 10.9 15 12 15C13.1 15 14 15.9 14 17C14 18.1 13.1 19 12 19Z" fill={_color} />
    </svg>
  );
}
