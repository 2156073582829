import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import tw from 'twin.macro';
import PrivateRoutes from '../../routes/PrivateRoutes';
import { Route as RouteData } from '../../routes/types';
import HomeView from '../../views/home';

const Container = tw.div`h-screen`;

export default function PrivateLayout(): JSX.Element {
  const location = useLocation();

  return (
    <Container>
      <Routes location={location} key={location.pathname}>
        <Route
          path="/*"
          element={<HomeView />}
        >
          <Route
            index
          />
        </Route>
        {PrivateRoutes.map((route: RouteData) => (
          route.component && (
          <Route
            key={route.name}
            path={route.path}
            element={<route.component />}
          />
          )
        ))}
      </Routes>
    </Container>
  );
}
