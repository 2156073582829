import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Event } from '../../constants/types';
import { EventHelperType } from '../../constants/constants';

interface State {
  event: Event,
}

const initialState: State = {
  event: {
    type: EventHelperType.Null,
    payload: null,
  },
};

export const eventHelperSlice = createSlice({
  name: 'eventHelper',
  initialState,
  reducers: {
    setEvent: (state, action: PayloadAction<Event>) => {
      state.event = action.payload;
    },
    resetEvent: (state) => {
      state.event = {
        type: EventHelperType.Null,
        payload: null,
      };
    },
  },
});

export const { setEvent, resetEvent } = eventHelperSlice.actions;

export default eventHelperSlice.reducer;
