export default {
  header: 'Loyalty Tablet - Stempelkarten revolutioniert',
  form: {
    username: {
      placeholder: 'Benutzername',
    },
    password: {
      placeholder: 'Passwort',
    },
    submit: 'Anmelden',
  },
  error_codes: {
    401: 'Ungültige Anmeldeinformationen',
    default: 'Da ist was schief gelaufen. Bitte versuche es erneut oder überprüfe die Internetverbindung.',
  },
};
