import * as React from 'react';
import { SvgProps } from '../types';

export default function WifiSlashIcon(props: SvgProps): JSX.Element {
  const {
    width,
    height,
    color,
    style,
    className,
  } = props;

  const _width = width ?? 32;
  const _height = height ?? _width * 0.875;
  const _color = color ?? '#000000';

  return (
    <svg
      width={_width}
      height={_height}
      viewBox={`0 0 ${_width} ${_height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path d="M27.5601 26.5332C27.9987 26.9581 28.6978 26.9444 29.1091 26.5332C29.534 26.0945 29.534 25.4091 29.1091 24.9979L4.43504 0.323867C4.0101 -0.101075 3.311 -0.114782 2.88606 0.323867C2.46112 0.7351 2.47483 1.44791 2.88606 1.85914L27.5601 26.5332ZM15.6206 9.12426C15.7851 9.09685 15.977 9.08314 16.1552 9.08314C21.2134 9.1791 25.4765 11.1256 28.8486 14.58C29.1365 14.8678 29.5751 14.8541 29.863 14.5525L31.7821 12.6198C32.0699 12.3456 32.0562 11.9892 31.8369 11.7287C28.4785 7.69865 22.3785 4.73777 16.0044 4.73777C14.6062 4.73777 13.208 4.87485 11.7002 5.19013L15.6206 9.12426ZM5.84694 7.27371C3.57145 8.45258 1.61124 9.98785 0.158212 11.7287C-0.0611127 11.9892 -0.0611127 12.3456 0.213043 12.6198L2.13213 14.5525C2.4337 14.8541 2.88606 14.8541 3.17392 14.5388C4.8874 12.7294 6.8339 11.3586 9.04086 10.4539L5.84694 7.27371ZM25.4765 18.9665L26.2853 18.1577C26.5594 17.8972 26.5731 17.5683 26.3401 17.2941C24.9282 15.5532 22.5156 14.0591 19.9111 13.4011L25.4765 18.9665ZM12.0154 13.4148C9.36984 14.1413 7.12177 15.5532 5.65503 17.2941C5.422 17.5683 5.44942 17.911 5.70987 18.1577L7.86199 20.2687C8.19098 20.584 8.62962 20.5566 8.9449 20.2139C10.5761 18.4319 13.1258 17.2119 15.744 17.1433L12.0154 13.4148ZM16.0044 20.8581C14.0442 20.8581 12.2759 21.8862 11.3301 23.1747C11.1656 23.4215 11.2341 23.7231 11.4808 23.9561L14.8393 27.1911C15.3876 27.7257 15.6754 27.8765 16.0044 27.8765C16.3471 27.8765 16.635 27.712 17.1833 27.1911L20.5417 23.9561C20.7884 23.7231 20.8433 23.3392 20.6239 23.0651C19.6644 21.8451 17.9372 20.8581 16.0044 20.8581Z" fill={_color} />
    </svg>
  );
}
