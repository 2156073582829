export enum EventHelperType {
  Null = '',
  LogOut = 'LOG_OUT',
}

export enum SupportedLanguages {
  en = 'en',
  de = 'de',
}

export enum LoyaltyPointsSource {
  LoyaltyTablet = 2,
  StampCard = 8,
}
