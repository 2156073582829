import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RewardAccountData } from '../../constants/responses';

interface State {
  rewardAccount: RewardAccountData,
}

const initialState: State = {
  rewardAccount: {
    id: null,
    phone_number: '',
    username: null,
    should_ask_stamp_card: false,
    has_filled_stamp_card: false,
    is_initial_usage: true,
    balance: 0,
    last_operation_value: 0,
    rewards: [],
  },
};

export const rewardAccountSlice = createSlice({
  name: 'rewardAccount',
  initialState,
  reducers: {
    setRewardAccount: (state, action: PayloadAction<RewardAccountData|undefined>) => {
      // @ts-ignore
      state.rewardAccount = action?.payload;
    },
    resetRewardAccount: (state) => {
      state.rewardAccount = initialState.rewardAccount;
    },
  },
});

export const { setRewardAccount, resetRewardAccount } = rewardAccountSlice.actions;

export default rewardAccountSlice.reducer;
