import React, {
  createContext, useEffect, useMemo, useReducer, useState,
} from 'react';
import { VechaiProvider } from '@vechaiui/react';
import { AnyAction } from 'redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Detector } from 'react-detect-offline';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import AuthorizationTokenStorage
  from './services/auth/AuthorizationTokenStorage';
import { LoginData } from './constants/types';
import ReduxHooks from './store/ReduxHooks';
import { setConfig } from './store/slices/ConfigSlice';
import { EventHelperType } from './constants/constants';
import HelperMethods from './services/helpers/HelperMethods';
import AuthLayout from './components/layout/AuthLayout';
import PrivateLayout from './components/layout/PrivateLayout';
import ConfigManager from './services/api/ConfigManager';
import { useTheme } from './hooks/useTheme';
import ConnectionLostBanner from './components/banners/ConnectionLostBanner';
import ConnectionEstablishedBanner
  from './components/banners/ConnectionEstablishedBanner';
import SlackReporter from './services/exception-handler/SlackReporter';
import { useCleanup } from './hooks/useCleanup';
import ErrorView from './views/error';

export const AuthContext = createContext(null);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

export default function App(): JSX.Element {
  // @ts-ignore
  const { eventHelper } = useReducer((state: any) => state);

  const [hasConnection, setHasConnection] = useState(true);
  const [showBanner, setShowBanner] = useState(false);

  const cleanup = useCleanup();

  const [state, dispatch] = useReducer(
    (prevState: any, action: AnyAction) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            mounted: true,
            token: action.token,
            isLoading: false,
          };
        case 'LOG_IN':
          return {
            ...prevState,
            isLogout: false,
            token: action.token,
          };
        case 'LOG_OUT':
          return {
            ...prevState,
            isLogout: true,
            token: null,
          };
        default:
          return null;
      }
    },
    {
      mounted: false,
      isLogout: false,
      token: null,
    },
  );

  useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    dispatch({
      type: 'RESTORE_TOKEN',
      token: AuthorizationTokenStorage.getToken(),
    });
  }, []);

  useEffect(() => {
    if (!state.mounted || !state.token || state.isLogout) {
      return;
    }

    loadConfig();
  }, [state]);

  useEffect(() => {
    handleReduxSideEffects();
  }, [eventHelper]);

  const authContext = useMemo(
    () => ({
      logIn: async (data: LoginData) => {
        try {
          await AuthorizationTokenStorage.saveToken(data.token);
          dispatch({ type: 'LOG_IN', token: data.token });
        } catch (error) {
          console.error('Could not log the user in:', error);
        }
      },
      logOut: () => {
        try {
          AuthorizationTokenStorage.deleteToken();
          dispatch({ type: 'LOG_OUT' });
        } catch (error) {
          console.error('Could not log the user out:', error);
        }
      },
    }),
    [],
  );

  async function handleReduxSideEffects(): Promise<undefined|boolean|AnyAction> {
    try {
      const type = eventHelper?.event?.type;

      if (!type) {
        return;
      }

      new Promise((resolve) => {
        switch (type) {
          case EventHelperType.LogOut:
            authContext.logOut();
            break;
          default:
            break;
        }
        resolve(true);
      }).then(() => HelperMethods.resetEventSideEffect());
    } catch (error) {
      console.error('error handleReduxSideEffects: ', error);
    }
  }

  async function loadConfig(): Promise<void> {
    const { data, success } = await ConfigManager.get();

    if (success) {
      // @ts-ignore
      ReduxHooks.dispatch(setConfig(data.data));
    }
  }

  const handleConnectionLost = (): void => {
    setHasConnection(false);
    setShowBanner(true);
  };

  const handleConnectionEstablished = (): void => {
    setHasConnection(true);
    setShowBanner(true);

    setTimeout(() => setShowBanner(false), 3000);
  };

  const toggleConnection = (online: boolean): void => (online ? handleConnectionEstablished() : handleConnectionLost());

  const renderBanner = useMemo(() => {
    if (showBanner && !hasConnection) {
      return <ConnectionLostBanner />;
    }

    return showBanner && <ConnectionEstablishedBanner onDismiss={() => setShowBanner(false)} />;
  }, [showBanner, hasConnection]);

  function renderRoutes(): JSX.Element {
    if (!state.token) {
      return <AuthLayout />;
    }

    return <PrivateLayout />;
  }

  return (
    // @ts-ignore
    <AuthContext.Provider value={authContext}>
      <VechaiProvider theme={useTheme()} colorScheme="brandColorScheme">
        <Detector
          onChange={(online) => toggleConnection(online)}
          render={() => null}
        />
        <Router>
          <ErrorBoundary
            // eslint-disable-next-line
            FallbackComponent={ErrorView}
            onError={(error) => SlackReporter.report(error)}
            onReset={() => cleanup()}
          >
            <React.Suspense fallback={loading}>{renderRoutes()}</React.Suspense>
            <ToastContainer hideProgressBar />
          </ErrorBoundary>
        </Router>
      </VechaiProvider>
      {renderBanner}
    </AuthContext.Provider>
  );
}
