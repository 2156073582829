export default {
  header: 'Aktueller Stand',
  headers: {
    how_it_works: 'So funktioniert’s 👋',
  },
  texts: {
    how_it_works: 'Wir haben dir soeben eine SMS geschickt, wie du deine  Prämien über die FoodAmigos-App einlöst',
  },
  labels: {
    points_one: 'Punkt',
    points_other: 'Punkte',
    rewards: 'Prämien',
  },
  links: {
    stamp_card: 'Ich habe eine bestehende Treuekarte mit verdienten Stempeln ->',
  },
  buttons: {
    done: 'Fertig',
    redeem_reward: 'Jetzt Prämie einlösen 🎉',
  },
  modals: {
    redeem_reward: {
      title: 'Belohnung vorhanden 🎉',
      reward_one: '{{count}} Punkt einlösen',
      reward_other: '{{count}} Punkte einlösen',
      discount: '{{count}}% Rabatt',
      buttons: {
        redeem: 'Jetzt Prämie einlösen 🎉',
        not_now: 'Nicht, jetzt',
      },
    },
  },
  toasts: {
    reward_redeemed: 'Belohnung eingelöst. Sag unseren Mitarbeitern, was du als Belohnung bestellen magst',
    failed_to_redeem_reward: 'Einlösen der Belohnung fehlgeschlagen. Bitte versuche es erneut',
  },
};
