import * as React from 'react';
import { SvgProps } from '../types';

export default function XIcon(props: SvgProps): JSX.Element {
  const {
    width,
    height,
    color,
    style,
    className,
  } = props;

  const _width = width ?? 16;
  const _height = height ?? _width;
  const _color = color ?? '#FFFFFF';

  return (
    <svg
      width={_width}
      height={_height}
      viewBox={`0 0 ${_width} ${_height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path d="M14.0669 3.40023L12.6003 1.93359L8.00024 6.60026L3.40023 1.93359L1.93359 3.40023L6.60026 8.00024L1.93359 12.6003L3.40023 14.0669L8.00024 9.40023L12.6003 14.0669L14.0669 12.6003L9.40023 8.00024L14.0669 3.40023Z" fill={_color} />
    </svg>
  );
}
