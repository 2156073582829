import BackendApiClient from '../api/BackendApiClient';
import { PostLoginData } from '../../constants/requests';
import { Response } from '../api/types';
import { LoginResponseData } from '../../constants/responses';

class AuthorizationManager {
  login = async (data: PostLoginData): Promise<Response<LoginResponseData>> => BackendApiClient.requestAsync({
    method: 'POST',
    url: '/loyalty/login',
    data,
  });
}

export default new AuthorizationManager();
