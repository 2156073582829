import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import AuthorizationTokenStorage from '../auth/AuthorizationTokenStorage';
import { EventHelperType } from '../../constants/constants';
import { store } from '../../store';
import HelperMethods from '../helpers/HelperMethods';
import { Response } from './types';

class BackendApiClient {
  private client: AxiosInstance;

  private authToken = null;

  constructor() {
    this.client = axios.create({
      baseURL: this.getApiBaseUrl(),
      headers: {
        Authorization: `Bearer ${this.authToken}`,
      },
    });
  }

  async requestAsync(configuration: AxiosRequestConfig): Promise<Response<any>> {
    try {
      const response = await this.client.request(configuration);

      return {
        success: true,
        data: response.data,
        status: response.status,
      };
    } catch (error: any) {
      return {
        success: false,
        data: error.response?.data ?? null,
        status: error.response?.status ?? 500,
      };
    }
  }

  async requestAuthorized(configuration: AxiosRequestConfig): Promise<Response<any>> {
    const newConfig = await this.createRequestConfig(configuration);

    // Check if user token is not null
    // @ts-ignore
    if (newConfig?.headers?.Authorization.split('Bearer ')[1] === 'null') {
      return {
        success: false,
        data: null,
        status: 401,
      };
    }

    try {
      const response = await this.client.request(newConfig);

      return {
        success: true,
        data: response.data,
        status: response.status,
      };
    } catch (error: any) {
      if (error.response !== undefined) {
        const { status } = error.response;

        if (status === 401) {
          this.handleInvalidToken();
        }
      }

      console.error('Api call error: ', error);

      return {
        success: false,
        data: error.response?.data,
        status: error.response?.status,
      };
    }
  }

  async createRequestConfig(configuration: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    const token = await this.getAuthToken();

    return {
      ...configuration,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...configuration?.headers,
      },
    };
  }

  handleInvalidToken(): void {
    if (store.getState().eventHelper.event.type) {
      return;
    }

    HelperMethods.triggerEventSideEffects({
      type: EventHelperType.LogOut,
      payload: null,
    });
  }

  private getApiBaseUrl(): string|undefined {
    return process.env.REACT_APP_API_BASE_URL;
  }

  async getAuthToken(): Promise<string> {
    const { authToken } = this;
    if (authToken) {
      return authToken;
    }

    return AuthorizationTokenStorage.getToken() ?? '';
  }
}

export default new BackendApiClient();
