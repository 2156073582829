import React from 'react';
import { Route, Routes } from 'react-router-dom';
import tw from 'twin.macro';
import AuthRoutes from '../../routes/AuthRoutes';
import { Route as RouteData } from '../../routes/types';
import LoginView from '../../views/auth';

const Container = tw.div`h-screen flex justify-center p-2 bg-base`;

export default function AuthLayout(): JSX.Element {
  return (
    <Container>
      <Routes>
        <Route
          path="/*"
          element={<LoginView />}
        >
          <Route
            index
          />
        </Route>
        {AuthRoutes.map((route: RouteData) => (
          route.component && (
            <Route
              key={route.name}
              path={route.path}
              element={<route.component />}
            />
          )
        ))}
      </Routes>
    </Container>
  );
}
