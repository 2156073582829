import ReduxHooks from '../../store/ReduxHooks';

const TOKEN_KEY = 'AMIGO_LOYALTY_API_AUTH_TOKEN';

class AuthorizationTokenStorage {
  getToken = (): null|string => localStorage.getItem(TOKEN_KEY);

  saveToken = async (token: string): Promise<boolean> => new Promise((resolve) => {
    localStorage.setItem(TOKEN_KEY, token);

    resolve(true);
  });

  deleteToken = (): void => {
    localStorage.removeItem(TOKEN_KEY);
    ReduxHooks.clearStore();
  };
}

export default new AuthorizationTokenStorage();
