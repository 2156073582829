import BackendApiClient from '../api/BackendApiClient';

class SlackReporter {
  public report(e: Error): void {
    BackendApiClient.requestAuthorized({
      method: 'POST',
      url: '/loyalty/report-exception',
      data: this.format(e),
    });
  }

  private format(e: Error): object {
    return {
      attachments: [
        {
          title: 'Message',
          color: 'danger',
          text: e.message,
          fallback: e.message,
          fields: [
            {
              title: 'Level',
              value: 'ERROR',
              short: false,
            },
            {
              title: 'Environment',
              value: process.env.REACT_APP_MODE,
              short: false,
            },
            {
              title: 'App',
              value: 'Loyalty Tablet App',
              short: false,
            },
            {
              title: 'Stack',
              value: `\`\`\`${e.stack?.slice(0, 1750)}\`\`\``,
              short: false,
            },
          ],
          mrkdwn_in: ['fields'],
        },
      ],
    };
  }
}

export default new SlackReporter();
