import React from 'react';
import classnames from 'classnames';
import AlertIcon from '../../resources/assets/images/icons/AlertIcon';
import XIcon from '../../resources/assets/images/icons/XIcon';

interface Props {
  type: '' | 'success' | 'warning' | 'error';
  text: string;
  icon?: JSX.Element;
  className?: string;

  isDismissible?: boolean;
  onDismiss?: () => void;
}
export default function Banner(props: Props): JSX.Element {
  const {
    type, text, icon, className, isDismissible, onDismiss,
  } = props;

  const renderIcon = (): JSX.Element => {
    if (icon) {
      return icon;
    }

    switch (type) {
      case 'error': return <AlertIcon />;
      default: return <AlertIcon />;
    }
  };

  return (
    <div
      className={classnames('absolute top-0 w-full text-white', className, {
        'bg-[#69CE8C]': type === 'success',
        'bg-[#FFEFED] text-black': type === 'warning',
        'bg-[#EB5757]': type === 'error',
      })}
    >
      <div className="px-16 py-5 flex justify-between">
        <div className="flex">
          {renderIcon()}
          <h6 className="h-fit my-auto ml-4">{text}</h6>
        </div>
        {isDismissible && (
          <div
            onClick={onDismiss}
            className="flex h-full my-auto"
          >
            <XIcon />
          </div>
        )}
      </div>
    </div>
  );
}
