import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConfigData } from '../../constants/responses';

interface State {
  config: ConfigData,
}

const initialState: State = {
  config: {
    lang: null,
    primary_color: '',
    secondary_color: '',
    header: '',
    subheader: '',
    home_screen_button_text: '',
    free_reward_text: null,
    max_stamps_per_usage: 1,
    image_url: '',
    logo_url: '',
  },
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<ConfigData|undefined>) => {
      // @ts-ignore
      state.config = action?.payload;
    },
    resetConfig: (state) => {
      state.config = initialState.config;
    },
  },
});

export const { setConfig, resetConfig } = configSlice.actions;

export default configSlice.reducer;
